<template>
	<div class="project-container">
		<div class="project-image-container"></div>
		<img class="project-image" :src="topImage" />
		<div class="project-text">
			<h2>Vaskerom</h2>
			<p>
				Ellingsen Byggservice bygger gjerne ditt neste vaskerom. Vi forstår at det ofte kan være et ork å vaske, men med våre smarte løsninger og faglige kompetanse trenger det ikke lenger være slik.  
				Med baderomsinnredning fra Aubo sørger vi for at din neste vask blir en lek!
			</p>
			<p>
				Ta kontakt for en uforpliktende og hyggelig konsultasjon. Kontaktdetaljer finner du <a href="/kontakt">her</a>
			</p>
		</div>
	</div>
</template>

<script>
export default {
	props: ['topImage'],
}
</script>

<style scoped>
.project-image {
	width: 1100px;
	max-width: 70%;
	padding-top: 2%;
}
</style>
